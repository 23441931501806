@import "Global";

.skills {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.skill-container {
  padding: 20px 0;
}

.skill-heading {
  border-style: dashed;
  border-width: 5px;
  border-color: $yellow;
  color: $dark;
}

.skill-description {
  font-size: 1.3em;
  color: $yellow;
  align-self: center;
  transition: all 0.3s ease-in-out;
  height: 80px;
  opacity: 1;
}

.fadingOut {
  opacity: 0;
}