.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

.nav-bar-item {
  background-color: #f1f4d8;
  font-size: 1.7rem;
  font-weight: 400;
  flex: 1 1 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}

.nav-bar-item:hover {
  background-color: #ff6572;
}

a {
  color: #00313c;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

a:hover {
  color: #f1f4d8;
  text-decoration: none;
}

.nav-bar-item.active {
  background-color: #00313c;
  color: #f1f4d8;
}

@media screen and (max-width: 1024px) {
  .nav-bar-item {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 768px) {
  .nav-bar-item {
    background-color: #f1f4d8;
    font-size: 2rem;
  }
}

