.notebook-illustration {
  width: 100%;
  position: relative;
  align-self: center;
}

.about-img {
  position: absolute;
  transition: all 0.1s ease-in-out;
}

.notebook-image {
  z-index: 4;
  height: 100%;
  left: 0;
}

.notebook-background {
  z-index: 2;
  top: -8%;
  left: -2%;
  height: 90%;
}

.code-background {
  z-index: 1;
  left: 5%;
  top: 0;
  height: 50%;
}

.cup-image {
  z-index: 4;
  height: 27%;
  right: 2%;
  top: 23%;
}

.pen-image {
  z-index: 4;
  height: 27%;
  right: 0;
  top: 43%;
}

.code-image {
  z-index: 1;
  height: 120%;
}

.smoke-1-image {
  z-index: 5;
  height: 15%;
  right: 5%;
  top: 2%;
}

.smoke-2-image {
  z-index: 5;
  height: 20%;
  right: 15%;
  top: 5%;
}