@import "Global";

.nav-bar-item {
  background-color: $beige;
  font-size: 1.7rem;
  font-weight: 400;
  flex: 1 1 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}

.nav-bar-item:hover {
  background-color: $red;
}

a {
  color: $dark;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

a:hover {
  color: $beige;
  text-decoration: none;
}

.nav-bar-item.active {
  background-color: $dark;
  color: $beige;
}

@media screen and (max-width: 1024px) {
  .nav-bar-item {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar-item {
    background-color: $beige;
    font-size: 2rem;
  }
}
