@import "Global.scss";

.start-text {
  align-self: flex-end;
  text-align: left;
}

.start-heading {
  border-style: dashed;
  border-width: 5px;
  border-color: $red;
}
