.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

.letter-icon {
  height: 40px;
  margin: 0 10px;
}

.heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.heading-container .contact-photo img {
  width: 200px;
  border-radius: 50%;
  margin: 30px;
}
.heading-container .contact-heading {
  border-style: dashed;
  border-width: 5px;
  border-color: #ff6572;
}
.heading-container .contact-subheading {
  width: 100%;
  flex-basis: auto;
  font-size: 1.5rem;
}
.heading-container .contact-link {
  font-size: 1.7rem;
  color: #ffcd8d;
  font-weight: bold;
}
.heading-container .contact-link:hover {
  color: #f1f4d8;
}

@media all and (max-width: 768px) {
  #contact-section .heading-container {
    margin-bottom: 0;
    width: 90%;
  }
}

