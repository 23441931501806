@import "Global.scss";

$item-height: 400px;
$arrow-height: 80px;

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: $arrow-height;
}

.project-carousel-section {
  flex: 1 1 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 1100px;
}

.carousel-inner {
  background-color: $beige;
  max-width: 800px;
}

.project-item {
  min-height: $item-height;

  .project-item-link {
    width: 100%;
    overflow-x: hidden;
    img {
      transition: all .5s ease;
    }
  }
  
  .project-item-image {
    height: 400px;
    width: 100%;
    display: flex;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
  }
  
  .project-text {
    position: absolute;
    bottom: 20px;
    right: -10px;
    max-width: 320px;
    font-weight: 400;
    background-color: $dark;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);
    padding: 20px 30px 20px 40px;

    .project-item-description {
      color: white;
      font-size: 1.1rem;
      text-align: left;
    }

    .project-time {
      color: $red;
      font-size: 1rem;
      text-align: right;
    }
  }
}

.carousel-control-prev {
  opacity: 1;
}

.carousel-control-next {
  opacity: 1;
}

.carousel-indicators {
  position: relative;
  z-index: 0;
}

.carousel-indicators li{
  transition: all 0.3s ease-in-out;
  border: 2px solid $dark;
  background-color: $beige;

  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.carousel-indicators .active {
  background-color: $dark;
}

.project-item-link:hover {
  img {
    transform: scale(1.1);
  }
}
