.nav-items {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 60%;
}

.nav-items-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  gap: 75px;
}

@media screen and (max-width: 1024px) {
  .nav-items-wrapper {
    gap: 35px;
  }
}

@media all and (max-width: 768px) {
  .nav-items {
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FFF;
    width: 0;
    z-index: -1;
    height: 100vh;
    overflow: hidden;
    padding: 140px 0;
    transition: all 0.5s ease-in-out;
  }

  .nav-items-wrapper {
    flex-direction: column;
    position: absolute;
    height: 50%;
    align-items: center;
  }

  .nav-items.active {
    width: 100%;
  }
}
