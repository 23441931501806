@import "Global";

.hamburger-menu {
  display: none;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 60px;
  height: 80px;
  margin-left: auto;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.patty {
  height: 20px;
  width: 100%;
  margin: auto 0;
  border-style: solid;
  border-width: 5px;
  border-color: $dark;
  border-radius: 5px;
  background-color: $beige;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .hamburger-menu.active {

    .patty-one {
      transform: translateY(13px) rotate(45deg);
    }

    .patty-two {
      transform: translateY(-13px) rotate(-45deg);
    }

    .patty-three {
      transform: translateX(400%);
    }
  }
}