.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

svg {
  height: 80px;
  transition: all 0.4s ease-in-out;
}

.backblack {
  transition: all 0.4s ease-in-out;
}

svg:hover .backblack {
  fill: #ff6572;
}

