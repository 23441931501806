@import "Global";

.skill-item.clickable-skill:hover {
  color: $dark;
  background-color: $beige;
  cursor: pointer;
}

.skill-item {
  min-width: 100px;
  max-width: 200px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: $dark;
  color: $beige;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  overflow: hidden;
}

.skill-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px;
}

.skill-description {
  font-size: 1rem;
  align-self: flex-start;
  margin: 10px;
}

.language-text {
  display: inline;
}

.tool-text {
  display: inline;
}

@media all and (max-width: 1024px) {
  .skill-item {
    min-width: 90px;
    max-width: 180px;
  }
  .skill-title {
    font-size: 1.4rem;
  }

  .skill-description{
    font-size: 1rem;
  }
}

// Tablet: 481 min - 768 max
// Go to column mode
@media all and (max-width: 768px) {
  .skill-item {
    min-width: 70px;
    max-width: 130px;
  }
  .skill-title {
    font-size: 1.2rem;
  }

  .skill-description{
    font-size: 0.9rem;
  }
}

// Mobile: 320 min - 480 max
// Make container even smaller
@media all and (max-width: 480px) {
  .skill-item {
    min-width: 60px;
    max-width: 110px;
  }
  .skill-title {
    font-size: 1rem;
  }

  .skill-description{
    font-size: 0.8rem;
  }
}
