.fancy-hand {
  width: 100%;
  position: relative;
  z-index: 1;
}
.fancy-hand * {
  position: absolute;
  transition: all 0.1s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fancy-hand .min-image {
  max-height: 20%;
  opacity: 0;
}
.fancy-hand .visible {
  opacity: 1;
}
.fancy-hand .hand-image {
  height: 90%;
  z-index: 1;
}
.fancy-hand .hand-image-eyes {
  height: 7.5%;
  transform: translate(-70%, 25%);
  z-index: 1;
}
.fancy-hand .hand-image-mouth {
  height: 8%;
  transform: translate(-85%, 30%);
  z-index: 1;
}

.visible {
  opacity: 1;
}

