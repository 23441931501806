.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 80px;
}

.project-carousel-section {
  flex: 1 1 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 1100px;
}

.carousel-inner {
  background-color: #f1f4d8;
  max-width: 800px;
}

.project-item {
  min-height: 400px;
}
.project-item .project-item-link {
  width: 100%;
  overflow-x: hidden;
}
.project-item .project-item-link img {
  transition: all 0.5s ease;
}
.project-item .project-item-image {
  height: 400px;
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}
.project-item .project-text {
  position: absolute;
  bottom: 20px;
  right: -10px;
  max-width: 320px;
  font-weight: 400;
  background-color: #00313c;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);
  padding: 20px 30px 20px 40px;
}
.project-item .project-text .project-item-description {
  color: white;
  font-size: 1.1rem;
  text-align: left;
}
.project-item .project-text .project-time {
  color: #ff6572;
  font-size: 1rem;
  text-align: right;
}

.carousel-control-prev {
  opacity: 1;
}

.carousel-control-next {
  opacity: 1;
}

.carousel-indicators {
  position: relative;
  z-index: 0;
}

.carousel-indicators li {
  transition: all 0.3s ease-in-out;
  border: 2px solid #00313c;
  background-color: #f1f4d8;
  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.carousel-indicators .active {
  background-color: #00313c;
}

.project-item-link:hover img {
  transform: scale(1.1);
}

