$yellow: #ffcd8d;
$dark: #00313c;
$beige: #f1f4d8;
$red: #ff6572;

.yellow {
  background-color: $yellow;
}

.dark {
  background-color: $dark;
}

.beige {
  background-color: $beige;
}

.red {
  background-color: $red;
}

.yellow-font {
  color: $yellow;
}

.dark-font {
  color: $dark;
}

.beige-font {
  color: $beige;
}

.red-font {
  color: $red;
}