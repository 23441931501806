.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

.skills {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.skill-container {
  padding: 20px 0;
}

.skill-heading {
  border-style: dashed;
  border-width: 5px;
  border-color: #ffcd8d;
  color: #00313c;
}

.skill-description {
  font-size: 1.3em;
  color: #ffcd8d;
  align-self: center;
  transition: all 0.3s ease-in-out;
  height: 80px;
  opacity: 1;
}

.fadingOut {
  opacity: 0;
}

