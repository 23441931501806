.fancy-hand{
  width: 100%;
  position: relative;
  z-index: 1;

  * {
    position: absolute;
    transition: all 0.1s ease-in-out;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .min-image {
    max-height: 20%;
    opacity: 0;
  }

  .visible {
    opacity: 1;
  }

  .hand-image {
    height: 90%;
    z-index: 1;
  }

  .hand-image-eyes {
    height: 7.5%;
    transform: translate(-70%, 25%);
    z-index: 1;
  }

  .hand-image-mouth {
    height: 8%;
    transform: translate(-85%, 30%);
    z-index: 1;
  }
}

.visible {
  opacity: 1;
}