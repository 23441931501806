@import "Global";

svg {
  height: 80px;
  transition: all 0.4s ease-in-out;
}

.backblack {
  transition: all 0.4s ease-in-out;
}

svg:hover .backblack {
  fill: $red;
}