.yellow {
  background-color: #ffcd8d;
}

.dark {
  background-color: #00313c;
}

.beige {
  background-color: #f1f4d8;
}

.red {
  background-color: #ff6572;
}

.yellow-font {
  color: #ffcd8d;
}

.dark-font {
  color: #00313c;
}

.beige-font {
  color: #f1f4d8;
}

.red-font {
  color: #ff6572;
}

.skill-item.clickable-skill:hover {
  color: #00313c;
  background-color: #f1f4d8;
  cursor: pointer;
}

.skill-item {
  min-width: 100px;
  max-width: 200px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #00313c;
  color: #f1f4d8;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  overflow: hidden;
}

.skill-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px;
}

.skill-description {
  font-size: 1rem;
  align-self: flex-start;
  margin: 10px;
}

.language-text {
  display: inline;
}

.tool-text {
  display: inline;
}

@media all and (max-width: 1024px) {
  .skill-item {
    min-width: 90px;
    max-width: 180px;
  }

  .skill-title {
    font-size: 1.4rem;
  }

  .skill-description {
    font-size: 1rem;
  }
}
@media all and (max-width: 768px) {
  .skill-item {
    min-width: 70px;
    max-width: 130px;
  }

  .skill-title {
    font-size: 1.2rem;
  }

  .skill-description {
    font-size: 0.9rem;
  }
}
@media all and (max-width: 480px) {
  .skill-item {
    min-width: 60px;
    max-width: 110px;
  }

  .skill-title {
    font-size: 1rem;
  }

  .skill-description {
    font-size: 0.8rem;
  }
}

